import useLang from '@/hooks/useLang';
import { rankListTabState } from '@/stores/config';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import HomeSectionContainer from '../SectionContainer';
import { RankListTabBet } from './bet';
import { useRankCountdown } from './count-down';
import { useRank, useRankTypes } from './rank-list.hook';
import { RankListTab } from './tab';

const useRanks = () => {
  const { rankTypes, options1, options2 } = useRankTypes();
  const rank1 = useRank(3, rankTypes);
  const rank2 = useRank(2, rankTypes);
  return {
    rank1,
    rank2,
    options1,
    options2,
    rankTypes,
  };
};

export const HomeRankList = () => {
  const navigate = useNavigate();
  const { getValueByLanguage } = useLang();
  const { rank1, rank2, rankTypes, options1, options2 } = useRanks();
  const tab = useRecoilValue(rankListTabState);

  const { count: count1 } = useRankCountdown(rank1.rankDetail?.countdown ?? 0);
  const { count: count2 } = useRankCountdown(rank2.rankDetail?.countdown ?? 0);

  const handleClickMore = () => {
    navigate('/rank/history');
  };

  if (options1.length === 0 && options2.length === 0) {
    return null;
  }

  if (options1.length === 0 && options2.length > 0) {
    return (
      <Box>
        <HomeSectionContainer
          title={getValueByLanguage(rank2.rankDetail?.showName)}
          total="Histórias"
          onClickMore={handleClickMore}
        >
          <Box bgcolor="#242123" borderRadius={1} overflow="hidden">
            <Box
              sx={{
                background: 'linear-gradient(180deg, #242123 0%, #2E282D 25%)',
              }}
              px={2}
              pb={0}
            >
              <RankListTabBet
                key="commission"
                type={2}
                count={count2}
                options={options2}
                rankTypes={rankTypes}
                rankDetail={rank2.rankDetail}
                onChangeRankType={(v) => rank2.setRankType(v)}
                isLoading={rank2.isLoading}
                isFetched={rank2.isFetched}
                refresh={rank2.refresh}
                rankType={rank2.rankType}
              />
            </Box>
          </Box>
        </HomeSectionContainer>
      </Box>
    );
  }

  if (options1.length > 0 && options2.length === 0) {
    return (
      <Box>
        <HomeSectionContainer
          title={getValueByLanguage(rank1.rankDetail?.showName)}
          total="Histórias"
          onClickMore={handleClickMore}
        >
          <Box bgcolor="#242123" borderRadius={1} overflow="hidden">
            <Box
              sx={{
                background: 'linear-gradient(180deg, #242123 0%, #2E282D 25%)',
              }}
              px={2}
              pb={0}
            >
              <RankListTabBet
                key="bet"
                type={3}
                count={count1}
                options={options1}
                rankTypes={rankTypes}
                rankDetail={rank1.rankDetail}
                onChangeRankType={(v) => rank1.setRankType(v)}
                isLoading={rank1.isLoading}
                isFetched={rank1.isFetched}
                refresh={rank1.refresh}
                rankType={rank1.rankType}
              />
            </Box>
          </Box>
        </HomeSectionContainer>
      </Box>
    );
  }

  return (
    <Box px={2}>
      <HomeSectionContainer
        title="Lista de classificação"
        total="Histórias"
        onClickMore={handleClickMore}
      >
        <Box bgcolor="#242123" borderRadius={1} overflow="hidden">
          <RankListTab
            title1={
              getValueByLanguage(rank1.rankDetail?.showName) ??
              'Ordem De Aposta'
            }
            title2={
              getValueByLanguage(rank2.rankDetail?.showName) ??
              'Comissão Do Agente'
            }
          />
          <Box
            sx={{
              background: 'linear-gradient(180deg, #242123 0%, #2E282D 25%)',
            }}
            px={2}
            pb={0}
          >
            {tab === 'bet' ? (
              <RankListTabBet
                key="bet"
                type={3}
                count={count1}
                options={options1}
                rankTypes={rankTypes}
                rankDetail={rank1.rankDetail}
                onChangeRankType={(v) => rank1.setRankType(v)}
                isLoading={rank1.isLoading}
                isFetched={rank1.isFetched}
                refresh={rank1.refresh}
                rankType={rank1.rankType}
              />
            ) : (
              <RankListTabBet
                key="commission"
                type={2}
                count={count2}
                options={options2}
                rankTypes={rankTypes}
                rankDetail={rank2.rankDetail}
                onChangeRankType={(v) => rank2.setRankType(v)}
                isLoading={rank2.isLoading}
                isFetched={rank2.isFetched}
                refresh={rank2.refresh}
                rankType={rank2.rankType}
              />
            )}
          </Box>
        </Box>
      </HomeSectionContainer>
    </Box>
  );
};
