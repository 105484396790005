import ICON_COMPLETE from '@/assets/icons/complete.png';
import ICON_TIME from '@/assets/icons/time.png';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useCountdown } from 'usehooks-ts';

// 72小时开始倒计时显示
const MAX_HOUR = 72;
const MAX_SECOND = MAX_HOUR * 60 * 60;

export const useRankCountdown = (countStart: number) => {
  const [count, { startCountdown, resetCountdown, stopCountdown }] =
    useCountdown({
      countStart: Math.max(countStart, 0),
      intervalMs: 1000,
    });

  useEffect(() => {
    if (countStart > 0) {
      resetCountdown();
      startCountdown();
    } else {
      stopCountdown();
    }
  }, [countStart]);

  return {
    count: countStart > 0 ? count : 0,
  };
};

interface Props {
  count: number;
}

export const CountDown = (props: Props) => {
  const { count } = props;

  // const [count, { startCountdown, resetCountdown }] = useCountdown({
  //   countStart: props.countStart,
  //   intervalMs: 1000,
  // });

  // useEffect(() => {
  //   if (props.countStart > 0) {
  //     resetCountdown();
  //     startCountdown();
  //   }
  // }, [props.countStart]);

  if (count <= 0) {
    return (
      <CountDownContainer isComplete>
        <img
          alt=""
          src={ICON_COMPLETE}
          width={14}
          height={14}
          style={{ minWidth: 14 }}
        />
        <Typography
          sx={{
            ml: 1,
            fontSize: 12,
            fontWeight: 700,
            lineHeight: '18px',
          }}
        >
          Evento finalizado, ranking foi gerado.
        </Typography>
      </CountDownContainer>
    );
  }

  if (count > MAX_SECOND) {
    return null;
  }

  return (
    <CountDownContainer isComplete={false}>
      <img
        alt=""
        src={ICON_TIME}
        width={12}
        height={12}
        style={{ minWidth: 12 }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ml: 0.5,
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '14px',
          }}
        >
          Duração do evento:
        </Typography>
        <Typography
          sx={{
            ml: 1.5,
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '18px',
          }}
        >
          {transformTime(count)}
        </Typography>
      </Box>
    </CountDownContainer>
  );
};

const CountDownContainer = (props: {
  isComplete: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        minWidth: 248,
        background: props.isComplete
          ? 'linear-gradient(90deg, rgba(73, 236, 158, 0.25) 0%, rgba(73, 236, 158, 0.06) 100%)'
          : 'linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.04) 100%)',
        height: 36,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {props.children}
    </Box>
  );
};

/**
 * 转换时间 HH:mm:ss
 * 支持补0
 * @param seconds number
 */
const transformTime = (seconds: number) => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const second = seconds % 60;

  return `${hour < 10 ? `0${hour}` : hour} : ${
    minute < 10 ? `0${minute}` : minute
  } : ${second < 10 ? `0${second}` : second}`;
};
