import { getTabBg } from '@/components/home/rank-list/tab';
import { ActivityDto } from '@/hooks/useActivity';
import useLang from '@/hooks/useLang';
import { inviteOpenAndWinTabState } from '@/stores/config';
import { InviteEventsInfoDto } from '@/types';
import { Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OpenBoxTab } from './OpenBoxTab';
import { WinCommission } from './WinCommission';
interface Props {
  info: InviteEventsInfoDto | null;
  detail: ActivityDto | null;
  onRefresh: () => Promise<void>;
}

export const OpenAndWinBox = (props: Props) => {
  const { detail } = props;
  const [tab, setTab] = useRecoilState(inviteOpenAndWinTabState);

  return (
    <Box px={2} mt={2}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <CustomTab tab={tab} setTab={setTab} />
        {tab === 'Open' ? (
          <OpenBoxTab
            info={props.info}
            detail={detail}
            onRefresh={props.onRefresh}
          />
        ) : (
          <WinCommission />
        )}
      </Box>
    </Box>
  );
};

const CustomTab = (props: {
  tab: string;
  setTab: (tab: 'Open' | 'Win') => void;
}) => {
  const { tab, setTab } = props;
  const { __ } = useLang();
  return (
    <Box
      sx={{
        height: '38px',
        mb: 1,
        display: 'flex',
        fontStyle: 'italic',
      }}
    >
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('Open');
        }}
        sx={{
          background: tab === 'Open' ? getTabBg().background : '',
          borderRadius: '8px 8px 0 0',
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="body1"
          fontWeight={700}
          lineHeight={1}
          sx={{ textAlign: 'center' }}
          color={tab === 'Open' ? 'text.primary' : 'text.secondary'}
        >
          {__('Open treasure chest')}
        </Typography>
      </Box>
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('Win');
        }}
        sx={{
          background: tab === 'Win' ? getTabBg().background : '',
          borderRadius: '8px 8px 0 0',
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="body1"
          fontWeight={700}
          lineHeight={1}
          color={tab === 'Win' ? 'text.primary' : 'text.secondary'}
        >
          {__('Win commission')}
        </Typography>
      </Box>
    </Box>
  );
};
