import { rankListTabState } from '@/stores/config';
import { colorConfig, getMiddleColor } from '@/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

export const getTabBg = () => {
  const { domain } = colorConfig();
  const background = '#242123';

  // linear-gradient(180deg, #49B6FF 0%, #356F99 25%, #2B4C66 50%, #212933 100%), #212933

  const midColor = getMiddleColor(domain, background);
  const midColor2 = getMiddleColor(midColor, background);

  return {
    background: `linear-gradient(180deg, ${domain} 0%, ${midColor} 25%, ${midColor2} 50%, ${background} 100%), ${background}`,
  };
};

export const RankListTab = (props: { title1: string; title2: string }) => {
  // const { __ } = useLang();
  const [tab, setTab] = useRecoilState(rankListTabState);
  const background = getTabBg().background;

  return (
    <Box
      sx={{
        height: '38px',
        mb: 1,
        display: 'flex',
        fontStyle: 'italic',
      }}
    >
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('bet');
        }}
        sx={{
          background: tab === 'bet' ? background : '',
          borderRadius: '8px 8px 0 0',
          cursor: 'pointer',
        }}
      >
        {props.title1 ? (
          <Typography
            variant="body1"
            fontWeight={700}
            lineHeight={1}
            sx={{ textAlign: 'center' }}
            color={tab === 'bet' ? 'text.primary' : 'text.secondary'}
          >
            {props.title1}
          </Typography>
        ) : (
          <CircularProgress
            size={18}
            sx={{
              color: tab === 'bet' ? 'text.primary' : 'text.secondary',
            }}
          />
        )}
      </Box>
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('agent');
        }}
        sx={{
          background: tab === 'agent' ? background : '',
          borderRadius: '8px 8px 0 0',
          cursor: 'pointer',
        }}
      >
        {!props.title2 ? (
          <CircularProgress
            size={18}
            sx={{
              color: tab === 'agent' ? 'text.primary' : 'text.secondary',
            }}
          />
        ) : (
          <Typography
            variant="body1"
            fontWeight={700}
            lineHeight={1}
            color={tab === 'agent' ? 'text.primary' : 'text.secondary'}
          >
            {props.title2}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const RankListTabContent = (props: {
  bet: React.ReactNode;
  agent: React.ReactNode;
}) => {
  const tab = useRecoilValue(rankListTabState);

  if (tab === 'bet') {
    return props.bet;
  }

  return props.agent;
};
