import ICON_COIN from '@/assets/icons/coin.png';
import BG from '@/assets/images/rank-bg.png';
import EmptyData from '@/components/EmptyData';
import { DaysSelect } from '@/components/home/DaysSelect';
import {
  RankDetailDto,
  RankHistoryRuleDto,
  useRank,
  useRankHistory,
  useRankTypes,
} from '@/components/home/rank-list/rank-list.hook';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { HistoryAccordion } from './components/history-accordion';
import { PageContainer } from './components/page-container';
import { RankPageTabs } from './components/tabs';
import { Header } from './header';

import ICON_TOP_1 from '@/assets/icons/top_1.png';
import ICON_TOP_2 from '@/assets/icons/top_2.png';
import ICON_TOP_3 from '@/assets/icons/top_3.png';
import Center from '@/components/center';
import { RefreshBox } from '@/components/home/rank-list/refresh-box';
import useLang from '@/hooks/useLang';
import useScrollToTop from '@/hooks/useScrollToTop';

const useRanks = () => {
  const { rankTypes, options1, options2 } = useRankTypes();
  const rank1 = useRank(3, rankTypes);
  const rank2 = useRank(2, rankTypes);
  return {
    rank1,
    rank2,
    options1,
    options2,
    rankTypes,
  };
};

const RankHistoryPage = () => {
  const [tab, setTab] = useState<3 | 2>(3);
  const { getValueByLanguage } = useLang();
  useScrollToTop();

  const { rank1, rank2, rankTypes, options1, options2 } = useRanks();
  // const {
  //   rankDetail: rankDetailWinning,
  //   rankType: rankTypeWinning,
  //   setRankType: setRankTypeWinning,
  // } = useRank(3, rankTypes);
  // const {
  //   rankDetail: rankDetailCommission,
  //   rankType: rankTypeCommission,
  //   setRankType: setRankTypeCommission,
  // } = useRank(2, rankTypes);

  const rankType = tab === 3 ? rank1.rankType : rank2.rankType;
  const days = rankType?.cycle || 7;

  useEffect(() => {
    if (options1.length === 0 && options2.length > 0) {
      setTab(2);
    }
  }, [options1, options2]);

  const onChangeDays = (v: number) => {
    if (tab === 3) {
      const newRankType = rankTypes.find((r) => r.type === 3 && r.cycle === v);
      rank1.setRankType(newRankType);
    }

    if (tab === 2) {
      const newRankType = rankTypes.find((r) => r.type === 2 && r.cycle === v);
      rank2.setRankType(newRankType);
    }
  };

  return (
    <PageContainer>
      <Box
        sx={{
          backgroundColor: 'background.default',
          backgroundImage: {
            xs: `url(${BG})`,
          },
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto',
          position: 'relative',
          mb: 0,
          mx: 'auto',
        }}
      >
        <Box
          id="rank-page-container"
          position="relative"
          height="100vh"
          sx={{ ...hideScrollbarY }}
        >
          <Header title="Historical Winnings" />
          <Box px={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <RankPageTabs
                hasTab3={options1.length > 0}
                hasTab2={options2.length > 0}
                title3={getValueByLanguage(rank1.rankDetail?.showName)}
                title2={getValueByLanguage(rank2.rankDetail?.showName)}
                tab={tab}
                onChange={setTab}
              />
              <DaysSelect
                options={tab === 3 ? options1 : options2}
                value={days}
                onChange={onChangeDays}
              />
            </Box>
            {tab === 3 ? (
              <RankHistoryTabWinning
                isLoading={rank1.isLoading}
                isFetched={rank1.isFetched}
                refresh={rank1.refresh}
                key={3}
                data={rank1.rankDetail}
              />
            ) : (
              <RankHistoryTabWinning
                isLoading={rank2.isLoading}
                isFetched={rank2.isFetched}
                refresh={rank2.refresh}
                key={2}
                data={rank2.rankDetail}
              />
            )}
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default RankHistoryPage;

const RankHistoryTabWinning = (props: {
  data?: RankDetailDto;
  isLoading: boolean;
  isFetched: boolean;
  refresh: () => Promise<void>;
}) => {
  const [expandedRound, setExpandedRound] = useState<number>();
  const [nodataCount, setNodataCount] = useState<number>(0);

  if (!props.data && props.isFetched) {
    return (
      <Box>
        <RefreshBox loading={props.isLoading} refresh={props.refresh} />
      </Box>
    );
  }

  if (
    nodataCount > 0 &&
    !props.isLoading &&
    props.isFetched &&
    nodataCount === props.data?.rankingRounds?.length
  ) {
    return (
      <Center pt={8}>
        <EmptyData />
      </Center>
    );
  }

  return (
    <Box mt={1} pb={2}>
      {props.data?.rankingRounds?.map((round) => {
        return (
          <RankHistoryItem
            key={round.round}
            rankingBoardId={props.data?.rankingBoardId || ''}
            round={round.round}
            expanedRound={expandedRound}
            onExpand={(round) => {
              setExpandedRound((prev) => (prev === round ? undefined : round));
            }}
            onNoData={() => {
              setNodataCount((prev) => prev + 1);
            }}
          />
        );
      })}

      {!props.isLoading &&
        props.isFetched &&
        (!props.data?.rankingRounds ||
          props.data?.rankingRounds.length === 0) && (
          <Center pt={8}>
            <EmptyData />
          </Center>
        )}
    </Box>
  );
};

export const RankHistoryItem = (props: {
  rankingBoardId: string;
  round: number;
  expanedRound?: number;
  onExpand?: (round: number) => void;
  onNoData?: () => void;
}) => {
  // const [expanded, setExpanded] = useState<boolean>(false);
  const expanded = props.expanedRound === props.round;

  const { rankHistory, isLoading } = useRankHistory(
    props.rankingBoardId,
    props.round,
  );

  useEffect(() => {
    if (!isLoading && !rankHistory) {
      props.onNoData?.();
    }
  }, [rankHistory, isLoading]);

  const isEmpty = !rankHistory?.rankingRuleList?.length;

  if (isLoading) {
    return (
      <Skeleton
        sx={{ borderRadius: '6px', my: 1.5 }}
        variant="rectangular"
        width="100%"
        height="48px"
      />
    );
  }

  if (!rankHistory) {
    return null;
  }

  return (
    <HistoryAccordion
      expanded={expanded}
      onChange={() => props.onExpand?.(props.round)}
      header={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="body1" fontWeight={500} lineHeight={1}>
            {dayjs((rankHistory?.sTime || 0) * 1000).format('DD-MM-YYYY')}
            <span style={{ margin: '0 8px' }}>a</span>
            {dayjs((rankHistory?.eTime || 0) * 1000).format('DD-MM-YYYY')}
          </Typography>
        </Box>
      }
      content={
        <Box>
          {rankHistory?.rankingRuleList?.map((rule, index) => (
            <RankHistoryRankItem
              key={rule.sRanking}
              data={rule}
              isLast={index === rankHistory.rankingRuleList.length - 1}
            />
          ))}
          {isEmpty && <EmptyData />}
        </Box>
      }
    ></HistoryAccordion>
  );
};

const RankHistoryRankItem = (props: {
  data: RankHistoryRuleDto;
  isLast: boolean;
}) => {
  if (props.data.sRanking >= 1 && props.data.eRanking <= 3) {
    return <RankHistoryRankItemTop3 data={props.data} />;
  }

  return (
    <Box
      sx={{
        p: 2,
        pr: 3,
        background: 'linear-gradient(90deg, #3D333C 0%, #2E282D 100%)',
        display: 'flex',
        alignItems: 'center',
        mb: props.isLast ? 0 : 1.5,
        borderRadius: '6px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '4px',
          minWidth: '40px',
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #3D333C',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body2" lineHeight={1}>
          Rank
        </Typography>
        <Typography variant="subtitle2" lineHeight={1} fontWeight={700}>
          {props.data.sRanking === props.data.eRanking
            ? props.data.sRanking
            : `${props.data.sRanking} - ${props.data.eRanking}`}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 'auto',
          minWidth: 0,
          ml: 2,
          mr: 1,
        }}
      >
        <Grid container columns={4} spacing={3}>
          {props.data.userRankingList.map((item, index) => (
            <Grid item xs={4} key={item.userId}>
              <UserAmount
                userId={item.userId}
                rankingValue={item.rankingValue}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 'none',
        }}
      >
        <img
          src={ICON_COIN}
          alt=""
          width={12}
          height={12}
          style={{ minWidth: 12 }}
        />
        <Typography fontSize={20} fontWeight={700} ml="2px">
          {props.data.prizeAmt}
        </Typography>
      </Box>
    </Box>
  );
};

const RankHistoryRankItemTop3 = (props: { data: RankHistoryRuleDto }) => {
  const rank = props.data.sRanking;
  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        mb: 1.5,
        borderRadius: '6px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        background:
          'linear-gradient(90deg, rgba(229, 140, 219, 0.35) 0%, rgba(229, 140, 219, 0.00) 100%), #3D333C',
      }}
    >
      <img
        src={rank === 1 ? ICON_TOP_1 : rank === 2 ? ICON_TOP_2 : ICON_TOP_3}
        alt=""
        width={24}
        height={24}
        style={{ minWidth: 24 }}
      />
      <Box
        ml={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 'auto',
          minWidth: 0,
        }}
      >
        <UserAmount
          userId={props.data.userRankingList?.[0]?.userId || ''}
          rankingValue={props.data.userRankingList?.[0]?.rankingValue || 0}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={ICON_COIN}
            alt=""
            width={12}
            height={12}
            style={{ minWidth: 12 }}
          />
          <Typography fontSize={20} fontWeight={700} ml="2px">
            {props.data.prizeAmt}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
  // return (
  //   <>
  //     {Array.from({ length: props.data.eRanking }).map((_, index) => {
  //       const item = props.data.userRankingList[index];
  //       return (
  //         <Box
  //           key={`${item.userId}-${index}`}
  //           sx={{
  //             px: 3,
  //             py: 2,
  //             mb: 1.5,
  //             borderRadius: '6px',
  //             overflow: 'hidden',
  //             display: 'flex',
  //             alignItems: 'center',
  //             background:
  //               'linear-gradient(90deg, rgba(229, 140, 219, 0.35) 0%, rgba(229, 140, 219, 0.00) 100%), #3D333C',
  //           }}
  //         >
  //           <img
  //             src={
  //               index === 0 ? ICON_TOP_1 : index === 1 ? ICON_TOP_2 : ICON_TOP_3
  //             }
  //             alt=""
  //             width={24}
  //             height={24}
  //             style={{ minWidth: 24 }}
  //           />
  //           <Box
  //             ml={3}
  //             sx={{
  //               display: 'flex',
  //               alignItems: 'center',
  //               justifyContent: 'space-between',
  //               flex: 'auto',
  //               minWidth: 0,
  //             }}
  //           >
  //             <UserAmount
  //               userId={item.userId}
  //               rankingValue={item.rankingValue}
  //             />
  //             <Box
  //               sx={{
  //                 display: 'flex',
  //                 alignItems: 'center',
  //               }}
  //             >
  //               <img
  //                 src={ICON_COIN}
  //                 alt=""
  //                 width={12}
  //                 height={12}
  //                 style={{ minWidth: 12 }}
  //               />
  //               <Typography fontSize={20} fontWeight={700} ml="2px">
  //                 {props.data.prizeAmt}
  //               </Typography>
  //             </Box>
  //           </Box>
  //         </Box>
  //       );
  //     })}
  //   </>
  // );
};

const UserAmount = (props: { userId: string; rankingValue: number }) => {
  return (
    <Box>
      <Typography variant="subtitle2" fontWeight={700} lineHeight={'18px'}>
        {props.userId}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        fontWeight={700}
        height="16px"
        mt={1}
      >
        {props.rankingValue}
      </Typography>
    </Box>
  );
};
