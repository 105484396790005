import IMAGE_TIMEOUT from '@/assets/images/network-timeout.png';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';

export const RefreshBox = (props: {
  loading: boolean;
  refresh: () => Promise<void>;
}) => {
  return (
    <Box
      sx={{
        px: 2,
        pb: 3,
      }}
    >
      <Box maxWidth="280px" mx="auto" textAlign="center" pt={3}>
        <img src={IMAGE_TIMEOUT} alt="" width={280} height={210} />
        <Typography mt={2} sx={{ maxWidth: '280px' }}>
          Tempo limite de acesso, atualize e tente novamente
        </Typography>
        <LoadingButton
          loading={props.loading}
          variant="contained"
          onClick={async () => {
            await props.refresh();
          }}
          sx={{
            mt: 3,
            width: '200px',
            height: '48px',
            borderRadius: '999px',
          }}
        >
          Refresh
        </LoadingButton>
      </Box>
    </Box>
  );
};
