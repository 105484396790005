import DrawerHeder from '@/pages/Me/components/DrawerHeder';
import { globalMaxWidth } from '@/theme';
import { Box, Drawer, Typography } from '@mui/material';

interface Props {
  open: boolean;
  desc: string;
  onClose: () => void;
}

export const RulesDrawer = (props: Props) => {
  return (
    <Drawer
      anchor="bottom"
      open={props.open}
      // onOpen={() => setOpenWithdraw(true)}
      ModalProps={{
        container: document.getElementById('root') as HTMLElement,
      }}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          borderRadius: '24px 24px 0 0',
          maxWidth: globalMaxWidth,
          left: {
            xs: 0,
            sm: `calc(50% - ${globalMaxWidth / 2}px)`,
          },
        },
      }}
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: Math.min(window.innerWidth, globalMaxWidth),
          // height: isMobile ? '75vh' : '100vh',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        <DrawerHeder title="Rules" onClose={props.onClose} />

        <Box
          p={2}
          pt={1}
          sx={{
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
            }}
            variant="body1"
            fontWeight={500}
            lineHeight={1.5}
          >
            {props.desc}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
