import { ColorfulBg } from '@/styles';
import { IDeposit } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, ListItemButton, Stack, Typography } from '@mui/material';

interface Props {
  depositList: IDeposit[];
  selectedDeposit: IDeposit | null;
  onSelect: (item: IDeposit) => void;
}

const DepositPaymentMethods: React.FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        ...hideScrollbarX,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Stack direction="row" spacing={1}>
        {props.depositList.map((item, index) => {
          const isSelected = item.id === props.selectedDeposit?.id;
          return (
            <ListItemButton
              key={item.id + index}
              sx={{
                bgcolor: 'background.neutral',
                borderRadius: 1,
                borderColor: isSelected ? ColorfulBg() : 'transparent',
                borderWidth: '1px',
                borderStyle: 'solid',
                px: 1.5,
                py: 1,
                // flexGrow: 1,
                // flexBasis: '100px',
                flex: 'none',
                minWidth: '88px',
              }}
              onClick={() => props.onSelect(item)}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <img
                  alt=""
                  src={item.pic}
                  style={{
                    width: 24,
                    height: 24,
                    minWidth: 24,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    verticalAlign: 'middle',
                  }}
                />
                <Typography>{item.title}</Typography>
              </Stack>
            </ListItemButton>
          );
        })}
      </Stack>
    </Box>
  );
};

export default DepositPaymentMethods;
