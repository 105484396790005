import ICON_COIN from '@/assets/icons/coin.png';
import IC_QUESTION from '@/assets/icons/icon_question.png';
import ICON_TIP from '@/assets/icons/icon_tip2.png';
import IMAGE_RANK_COINS from '@/assets/images/rank-coins.png';
import EmptyData from '@/components/EmptyData';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { DaysToggler } from '../DaysSelect';
import { CountDown } from './count-down';
import { MyRank } from './my-rank';
import { RankListIndex } from './rank-list-index';
import { RankDetailDto, RankTypeDto } from './rank-list.hook';
import { RulesDrawer } from './rank-rules-drawer';
import { RefreshBox } from './refresh-box';

interface Props {
  rankTypes: RankTypeDto[];
  options: Array<{
    label: string;
    value: number;
  }>;
  type: number;

  rankDetail?: RankDetailDto;
  onChangeRankType: (type?: RankTypeDto) => void;
  isLoading: boolean;
  isFetched: boolean;
  rankType?: RankTypeDto;
  count: number;
  refresh: () => Promise<void>;
}

export const RankListTabBet = (props: Props) => {
  // const [days, setDays] = useState<number>(7);
  const { rankDetail, onChangeRankType, isLoading, rankType, isFetched } =
    props;
  const { user } = useAuth();
  const [openRules, setOpenRules] = useBoolean();
  const { getValueByLanguage } = useLang();

  // const { rankDetail, isLoading, rankType, setRankType } = useRank(
  //   props.type,
  //   props.rankTypes,
  // );
  const days = rankType?.cycle || 7;

  // const rankListWithoutTop3 = useMemo(() => {
  //   return rankDetail?.userRankingList.slice(3);
  // }, [rankDetail]);

  const isSelfRankInList = useMemo(() => {
    if (!rankDetail?.selfRanking) {
      return false;
    }

    return rankDetail?.userRankingList?.some(
      (item) => item.ranking === rankDetail?.selfRanking,
    );
  }, [rankDetail]);

  const onChangeDays = (v: number) => {
    const newRankType = props.rankTypes.find(
      (r) => r.type === props.type && r.cycle === v,
    );
    onChangeRankType(newRankType);
  };

  if (!rankDetail && isFetched) {
    return (
      <Box height={382}>
        <RefreshBox loading={isLoading} refresh={props.refresh} />
      </Box>
    );
  }

  if (!rankDetail) {
    return <Box height={382}></Box>;
  }

  // const hasRankingRuleList =
  //   rankDetail &&
  //   Array.isArray(rankDetail?.rankingRuleList) &&
  //   rankDetail.rankingRuleList.length > 0;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="body1" lineHeight="16px" fontWeight={700}>
            {dayjs(+(rankDetail.sTime || 0) * 1000).format('DD-MM-YYYY')} a{' '}
            {dayjs(+(rankDetail.eTime || 0) * 1000).format('DD-MM-YYYY')}
          </Typography>
          <Box
            mt={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            color="text.secondary"
            onClick={setOpenRules.on}
          >
            <img
              src={IC_QUESTION}
              width={14}
              height={14}
              style={{ minWidth: 14, marginRight: '4px' }}
              alt=""
            />
            <Typography variant="body2" lineHeight={1} color="text.secondary">
              Detalhes das regras
            </Typography>
          </Box>
        </Box>
        <Box>
          <DaysToggler
            options={props.options}
            value={days}
            onChange={onChangeDays}
          />
        </Box>
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <CountDown count={props.count} />
      </Box>
      {/* {hasRankingRuleList ? (
        <Box mt={3}>
          <RewardItemsContainer
            bottom="42px"
            size={80}
            content={
              <Box>
                <CommonRewards rewards={rankDetail.rankingRuleList} />
                <MyRank
                  rank={rankDetail.selfRanking}
                  value={rankDetail.selfRankingValue}
                  type={props.type}
                  refresh={props.refresh}
                  loading={isLoading}
                />
              </Box>
            }
          >
            <TopRewards data={rankDetail.rankingRuleList} />
          </RewardItemsContainer>
        </Box>
      ) : null} */}
      {/* <Box >
        <Top3
          isLoading={isLoading}
          userRankingList={rankDetail.userRankingList}
        />
      </Box> */}
      <MyRank
        rank={rankDetail.selfRanking}
        value={rankDetail.selfRankingValue}
        type={props.type}
        refresh={props.refresh}
        loading={isLoading}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Typography variant="body2" lineHeight={1} color={'text.secondary'}>
          {props.type === 3
            ? 'ID jogador e apostas total'
            : 'ID agente e recarga da equipe'}
        </Typography>
        <Typography variant="body2" lineHeight={1} color={'text.secondary'}>
          Prêmio
        </Typography>
      </Box>
      <Box
        sx={{
          height: '240px',
          position: 'relative',
        }}
        mt={1.5}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '240px',
            overflow: 'hidden',
            // background:
            //   'linear-gradient(180deg, rgba(61, 51, 60, 0.00) 0%, #3D333C 100%), #2E282D',
          }}
        >
          <Box
            sx={{
              minHeight: '160px',
              maxHeight: '240px',
              ...hideScrollbarY,
            }}
          >
            <Stack spacing={0.5}>
              {isLoading ? (
                <>
                  <Skeleton
                    sx={{ borderRadius: '6px' }}
                    variant="rectangular"
                    height="57px"
                    width="100%"
                  />
                  <Skeleton
                    sx={{ borderRadius: '6px' }}
                    variant="rectangular"
                    height="57px"
                    width="100%"
                  />
                  <Skeleton
                    sx={{ borderRadius: '6px' }}
                    variant="rectangular"
                    height="57px"
                    width="100%"
                  />
                </>
              ) : (
                rankDetail.userRankingList?.map((item, index) => {
                  // if (item.ranking === rankDetail?.selfRanking) {
                  //   return (
                  //     <RankItemMe
                  //       top={0}
                  //       key={`${item.userId}_${index}`}
                  //       rank={rankDetail?.selfRanking}
                  //       username={user?.name ?? 'Me'}
                  //       amount={rankDetail?.selfRankingValue ?? 0}
                  //     />
                  //   );
                  // }
                  const isMe = item.ranking === rankDetail?.selfRanking;

                  return (
                    <RankItem
                      key={`${item.userId}_${index}`}
                      rank={item.ranking}
                      prizeAmt={item.prizeAmt}
                      username={item.userId}
                      isMe={isMe}
                      amount={item.rankingValue}
                    />
                  );
                })
              )}
              {rankDetail?.selfRanking && !isSelfRankInList ? (
                <RankItem
                  isMe
                  prizeAmt={0}
                  rank={rankDetail?.selfRanking}
                  username={user?.name ?? 'Me'}
                  amount={rankDetail?.selfRankingValue ?? 0}
                />
              ) : null}

              {!isLoading && rankDetail?.userRankingList.length === 0 && (
                <Center height="200px">
                  <EmptyData />
                </Center>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box height="56px" position="relative" width="100%">
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '-16px',
            right: '-16px',
            background:
              'linear-gradient(180deg, rgba(46, 40, 45, 0.00) 0%, #2E282D 49.84%)',
            height: '104px',
            width: 'calc(100% + 32px)',
          }}
        ></Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '-8px',
            right: '-8px',
            height: '56px',
            pr: '112px',
            background:
              'linear-gradient(90deg, rgba(138, 151, 252, 0.75) 0%, rgba(138, 151, 252, 0.19) 100%)',
            backdropFilter: 'blur(6px)',
            borderRadius: '28px',
            display: 'flex',
            pl: 3,
            alignItems: 'center',
          }}
        >
          <Typography variant="body1" fontWeight={500} fontStyle="italic">
            {getValueByLanguage(rankDetail.maxPrizeAmtDesc)}
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: '16px',
            width: '80px',
            height: '80px',
          }}
        >
          <img src={IMAGE_RANK_COINS} alt="" width={80} height={80} />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 1,
        }}
      >
        <img src={ICON_TIP} alt="" width={10} height={10} />
        <Typography
          ml="2px"
          variant="body2"
          color="text.secondary"
          fontSize={10}
        >
          Lista atualizada a cada 5 minutos
        </Typography>
      </Box>
      <RulesDrawer
        desc={getValueByLanguage(props.rankDetail?.blurb)}
        open={openRules}
        onClose={setOpenRules.off}
      />
    </Box>
  );
};

export const RankItem = (props: {
  rank: number;
  username: string;
  amount: number;
  prizeAmt: number;
  isMe?: boolean;
}) => {
  return (
    <Box
      sx={
        props.isMe
          ? {
              background:
                'linear-gradient(90deg, rgba(229, 140, 219, 0.35) 0%, rgba(229, 140, 219, 0.00) 100%), #3D333C',

              position: 'sticky',
              bottom: 0,
              top: 0,
              borderRadius: 1,
            }
          : null
      }
      py={1}
      pr={1.5}
      pl={0}
      borderRadius="6px"
      overflow="hidden"
      display="flex"
      alignItems="center"
    >
      <RankListIndex rank={props.rank} />

      <Box
        sx={{
          mx: 1,
          flex: 'auto',
          minWidth: 0,
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 18,
            lineHeight: '21px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {props.username}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
            mt: 0.5,
          }}
        >
          <Typography sx={{ fontSize: 10, fontWeight: 500 }}>R$</Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              letterSpacing: '1px',
              ml: '2px',
              lineHeight: '16px',
            }}
          >
            {props.amount.toFixed(0)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flex: 'none',
          minWidth: '68px',
          opacity: props.prizeAmt > 0 ? 1 : 0,
        }}
      >
        <img src={ICON_COIN} alt="" width={12} height={12} />
        <Box
          sx={{
            fontWeight: 700,
            fontSize: 20,
            textAlign: 'right',
            ml: '2px',
          }}
        >
          {props.prizeAmt?.toFixed(0)}
        </Box>
      </Box>
    </Box>
  );
};

export const RankItemMe = (props: {
  rank: number;
  username: string;
  amount: number;
  top?: number;
}) => {
  // border-radius: 8px;
  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        top: props.top === undefined ? '44px' : `${props.top}px`,
        borderRadius: 1,
        background:
          'linear-gradient(90deg, rgba(229, 140, 219, 0.75) 0%, rgba(229, 140, 219, 0.19) 100%), #3D333C',
      }}
      py={2}
      pr={1.5}
      pl={0.5}
      display="flex"
      alignItems="center"
    >
      <Box
        sx={{
          fontSize: 14,
          fontWeight: 700,
          width: '32px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
      >
        {props.rank}
      </Box>
      <Box
        sx={{
          width: 72,
          fontWeight: 700,
          fontSize: 16,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flex: 'auto',
          minWidth: 0,
          mx: 1,
        }}
      >
        {props.username}
      </Box>

      <Box
        sx={{
          minWidth: 68,
          fontWeight: 700,
          fontSize: 16,
          flex: 'none',
          textAlign: 'right',
        }}
      >
        <span style={{ fontSize: '12px', fontWeight: 400 }}>R$</span>{' '}
        {props.amount?.toFixed(0)}
      </Box>
    </Box>
  );
};
