import { useCustomOffSetTop } from '@/hooks/useOffSetTop';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  right?: {
    text: string;
    onClick: () => void;
  };
}

export const Header = (props: Props) => {
  const navigate = useNavigate();
  const isOffset = useCustomOffSetTop(10, 'rank-page-container');

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      bgcolor={isOffset ? 'background.default' : 'transparent'}
      position="sticky"
      top={0}
      zIndex={100}
      left={0}
    >
      <Box
        sx={{
          height: '44px',
          px: 2,
          py: '10px',
          position: 'relative',
        }}
      >
        <Box
          width="24px"
          height="24px"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '16px',
            top: '10px',
          }}
        >
          <IconButton color="info" onClick={handleBack}>
            <ArrowBackIosNewRoundedIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Box>
        <Typography
          textAlign="center"
          lineHeight="24px"
          variant="subtitle1"
          fontWeight={700}
        >
          {props.title}
        </Typography>
        {props.right ? (
          <Box
            sx={{
              position: 'absolute',
              right: '16px',
              top: '10px',
              display: 'flex',
              alignItems: 'center',
              height: '24px',
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={props.right.onClick}
          >
            <Typography variant="body1" fontWeight={500} color="text.secondary">
              {props.right.text}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
