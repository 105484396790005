import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export const HistoryAccordion = (props: {
  expanded: boolean;
  onChange: () => void;
  header: React.ReactNode;
  content: React.ReactNode;
}) => {
  return (
    <Accordion
      expanded={props.expanded}
      onChange={props.onChange}
      sx={{
        my: '12px !important',
        borderRadius: '0 !important',
        background: 'transparent',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<Icon />}
        sx={{
          minHeight: '48px !important',
          borderRadius: 1,
          background: props.expanded
            ? '#3D333C'
            : 'linear-gradient(90deg, rgba(229, 140, 219, 0.15) 0%, rgba(229, 140, 219, 0.00) 100%), #3D333C',
          '.MuiAccordionSummary-content': {
            my: '16px !important',
          },
        }}
      >
        {props.header}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          bgcolor: '#242123',
          borderRadius: 1,
          p: '12px !important',
        }}
      >
        {props.content}
      </AccordionDetails>
    </Accordion>
  );
};

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00001 5.83203C3.79778 5.83203 3.61547 5.95385 3.53807 6.14069C3.46068 6.32753 3.50346 6.54259 3.64646 6.68558L7.64646 10.6856C7.84172 10.8808 8.15831 10.8808 8.35357 10.6856L12.3536 6.68558C12.4966 6.54259 12.5393 6.32753 12.462 6.14069C12.3846 5.95385 12.2022 5.83203 12 5.83203H4.00001Z"
        fill="white"
        fillOpacity="0.56"
      />
    </svg>
  );
};
