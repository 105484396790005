import ICON_COPY from '@/assets/icons/icon_copy.svg';
import useLang from '@/hooks/useLang';
import { userInfoState } from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useRecoilValue } from 'recoil';

interface Props {
  value: string;
}

export const InviteCopy = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { __ } = useLang();

  const user = useRecoilValue(userInfoState);

  const url = user?.agent_url || window.location.href;

  return (
    <Box>
      <Typography
        variant="subtitle2"
        fontWeight={500}
        fontStyle="italic"
        lineHeight={1}
        mb={1}
      >
        {__('Exclusive Invitation Link')}
      </Typography>
      <Box
        sx={{
          height: '50px',
          borderRadius: 1,
          bgcolor: 'background.paper',
          position: 'relative',
          mt: 0.5,
          pl: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: 'auto',
            minWidth: 0,
          }}
        >
          <Typography
            variant="subtitle1"
            className="futureBugler-font"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {url}
          </Typography>
        </Box>
        <Box p={0.5}>
          <CopyToClipboard
            text={url}
            onCopy={() =>
              enqueueSnackbar(__('Copied successfully'), { variant: 'success' })
            }
          >
            <Box
              sx={{
                px: 2,
                height: '40px',
                width: '48px',
                background: `linear-gradient(90deg, ${ColorfulBg()} 0%, #A6FFCB 100%)`,
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={ICON_COPY} alt="copy" width={16} height={16} />
            </Box>
          </CopyToClipboard>
        </Box>
      </Box>
    </Box>
  );
};
