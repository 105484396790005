import useLang from '@/hooks/useLang';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Box, Typography } from '@mui/material';
import { StatisticsTabs } from './Tabs';

interface Props {}

export const TabStatistics = (props: Props) => {
  const { __, isEn } = useLang();
  return (
    <Box
      height={'calc(100vh - 124px)'}
      id="invite-scroll-container"
      pb={2}
      sx={{
        ...hideScrollbarY,
      }}
    >
      <Typography
        py={3}
        px={2}
        variant="subtitle2"
        fontStyle="italic"
        fontWeight={700}
        fontSize={isEn ? 16 : 14}
        lineHeight={{ md: 1.25 }}
      >
        {__('The following data is updated every 10 minutes')}
        {isEn ? null : (
          <>
            <br />
            Obs.: Todos os jogos de pesca e o provedor Evo Play não participarão
            deste evento.
          </>
        )}
      </Typography>
      <StatisticsTabs />

      <Box p={2}>
        <Typography
          variant="subtitle2"
          lineHeight={1}
          pb={1}
          fontWeight={500}
          fontStyle="italic"
        >
          Descrição da regra
        </Typography>
        <Box
          p={2}
          borderRadius={1}
          bgcolor="background.paper"
          fontSize={14}
          fontStyle="italic"
          color="text.secondary"
        >
          Nível 1: refere-se aos jogadores que se registraram diretamente
          através do seu link de convite.
          <br />
          Nível 2: refere-se aos jogadores que se registraram através dos links
          de convite dos jogadores de Nível
        </Box>
      </Box>
    </Box>
  );
};
