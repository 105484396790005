import { globalMaxWidth } from '@/theme';
import { Box } from '@mui/material';

export const PageContainer = (props: { children: React.ReactNode }) => {
  const width = Math.min(window.innerWidth, globalMaxWidth);
  return (
    <Box
      sx={{
        width: `${width}px`,
        mx: 'auto',
      }}
    >
      {props.children}
    </Box>
  );
};
