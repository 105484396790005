// import IMAGE_TOTAL_COIN from '@/assets/invitebox/total-coin-bg.png';
import DatePicker from '@/components/DatePicker/DatePicker';
import { useAgentSummary2 } from '@/hooks/useAgentSummary';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

export const TabTotalData = () => {
  // const { __ } = useLang();

  const { data, setEnd, setStart, start, end } = useAgentSummary2();

  return (
    <Box px={1} pt={1} pb={2}>
      <Box
        sx={{
          border: `1px solid`,
          borderRadius: '5px',
          borderColor: 'primary.main',
          py: 0,
          px: 2.5,
          mb: 1,
        }}
      >
        <Stack
          sx={{ height: '40px' }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Stack
            textAlign="center"
            flex="auto"
            direction="row"
            alignItems="center"
          >
            <DatePicker
              date={start}
              onChange={(v) => setStart(v)}
              maxDate={dayjs(end)}
              anchorOriginHorizontal="left"
              transformOriginHorizontal="left"
            />
            <Typography variant="body1">-</Typography>
            <DatePicker
              date={end}
              onChange={(v) => setEnd(v)}
              minDate={dayjs(start)}
              anchorOriginHorizontal="right"
              transformOriginHorizontal="right"
            />
          </Stack>
          <CalendarMonthIcon sx={{ fontSize: 16 }} />
        </Stack>
      </Box>

      <Grid container spacing={1} columns={24}>
        <Grid item xs={24}>
          <Box
            sx={{
              background:
                'linear-gradient(180deg, rgba(163, 137, 160, 0.00) 0%, rgba(163, 137, 160, 0.25) 100%), #3D333C',
              p: 0,
              borderRadius: 1,
              overflow: 'hidden',
              pb: 1,
            }}
          >
            <Box p={1}>
              <Box pb={1}>
                <TotalDataCard
                  label="Recarga nível 1"
                  rs
                  value={Number(data?.['1LayerRechargeAmt'] ?? 0)}
                  bgColor="background.neutral"
                />
              </Box>
              <TotalDataCard
                label="Recarga nível 2"
                rs
                value={Number(data?.['2LayerRechargeAmt'] ?? 0)}
                bgColor="background.neutral"
              />
            </Box>

            <Box
              sx={{
                px: 1.5,
                py: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography fontSize={16} fontWeight={700}>
                  Recarga total
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  (nível 1 e 2)
                </Typography>
              </Box>
              <Box flex="none" display="flex" alignItems="center">
                <Typography fontWeight={16} mr="2px">
                  R$
                </Typography>
                <Typography fontSize={24} fontWeight={700}>
                  {Number(data?.['1LayerRechargeAmt'] ?? 0) +
                    Number(data?.['2LayerRechargeAmt'] ?? 0)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={24}>
          <Box
            sx={{
              background:
                'linear-gradient(180deg, rgba(163, 137, 160, 0.00) 0%, rgba(163, 137, 160, 0.25) 100%), #3D333C',
              p: 0,
              borderRadius: 1,
              overflow: 'hidden',
              pb: 1,
            }}
          >
            <Box p={1}>
              <Box pb={1}>
                <TotalDataCard
                  label="1° recarga nível 1"
                  value={Number(data?.['1LayerFirstRechargeTimes'] ?? 0)}
                  bgColor="background.neutral"
                />
              </Box>
              <TotalDataCard
                label="1° recarga nível 2"
                value={Number(data?.['2LayerFirstRechargeTimes'] ?? 0)}
                bgColor="background.neutral"
              />
            </Box>

            <Box
              sx={{
                px: 1.5,
                py: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography fontSize={16} fontWeight={700}>
                  Jogadores de 1° recarga
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  (nível 1 e 2)
                </Typography>
              </Box>
              <Box flex="none">
                <Typography fontSize={24} fontWeight={700}>
                  {Number(data?.['1LayerFirstRechargeTimes'] ?? 0) +
                    Number(data?.['2LayerFirstRechargeTimes'] ?? 0)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={24}>
          <Box
            sx={{
              p: 2,
              borderRadius: 1,
              bgcolor: '#313D4D',
              backgroundImage: `url(${IMAGE_TOTAL_COIN})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 85%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography mr={0.5} variant="subtitle2" color="text.secondary">
                R$
              </Typography>
              <Typography variant="h1">{data?.totalAgentAmt ?? 0}</Typography>
            </Box>
            <Typography mt={1} variant="body1" color="text.secondary">
              {__('Total Cumulative Prize Money')}
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

const TotalDataCard = ({
  label,
  value,
  bgColor,
  rs,
}: {
  label: string;
  value: number;
  rs?: boolean;
  bgColor: string;
}) => {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        bgcolor: bgColor,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" color="text.secondary">
          {label}
        </Typography>
        {rs ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography mr={'2px'} variant="body1">
              R$
            </Typography>
            <Typography variant="h2">{value}</Typography>
          </Box>
        ) : (
          <Typography variant="h2">{value}</Typography>
        )}
      </Stack>
    </Box>
  );
};
