export const AppBarBg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="48"
      viewBox="0 0 75 48"
      fill="none"
    >
      <path d="M0 0H75L63 48H12L0 0Z" fill="url(#paint0_linear_17444_87683)" />
      <defs>
        <linearGradient
          id="paint0_linear_17444_87683"
          x1="37.5"
          y1="0"
          x2="37.5"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E58CDB" stopOpacity="0" />
          <stop offset="1" stopColor="#E58CDB" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
