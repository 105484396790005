import IMAGE_TIMEOUT from '@/assets/images/network-timeout.png';
import EmptyData from '@/components/EmptyData';
import Center from '@/components/center';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { RecordCard } from '@/pages/InviteBoxPage/components/InviteRecordDrawer/RecordCard';
import { inviteEventsInfoState } from '@/stores/invite';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { StatisticsCard } from './StatisticsCard';

interface Props {
  records: any[];
  hasMore: boolean;
  loadMore: () => void;
  loading: boolean;
  refetch: () => Promise<void>;
  isTimeout: boolean;
}

export const OpeningData = (props: Props) => {
  const { records, hasMore, loadMore, loading } = props;
  const { __ } = useLang();
  const [isRefresh, setIsRefresh] = useBoolean();
  const eventInfo = useRecoilValue(inviteEventsInfoState);

  if (loading && records.length === 0) {
    return (
      <Box px={2} pt={1} pb={2}>
        <Box mb={1.5}>
          <Box
            sx={{
              background: 'linear-gradient(180deg, #36A8F4 0%, #18457C 100%)',
              width: '100%',
              height: 108,
              borderRadius: '6px',
            }}
          ></Box>
        </Box>
        <Grid container columns={24} spacing={1}>
          <Grid item xs={24}>
            <Skeleton
              variant="rounded"
              width="100%"
              animation="wave"
              sx={{ height: 162, bgcolor: 'background.neutral' }}
            ></Skeleton>
          </Grid>
          <Grid item xs={24}>
            <Skeleton
              variant="rounded"
              width="100%"
              animation="wave"
              sx={{ height: 162, bgcolor: 'background.neutral' }}
            ></Skeleton>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (props.records.length === 0 && props.isTimeout) {
    return (
      <Box pb={3}>
        <Box maxWidth="280px" mx="auto" textAlign="center" pt={3}>
          <img src={IMAGE_TIMEOUT} alt="" width={280} height={210} />
          <Typography mt={2} sx={{ maxWidth: '280px' }}>
            Tempo limite de acesso, atualize e tente novamente
          </Typography>
          <LoadingButton
            loading={isRefresh}
            variant="contained"
            onClick={async () => {
              setIsRefresh.on();
              await props.refetch();
              setIsRefresh.off();
            }}
            sx={{
              mt: 3,
              width: '200px',
              height: '48px',
              borderRadius: '999px',
            }}
          >
            Refresh
          </LoadingButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box px={2} pt={1} pb={2}>
      <Box mb={1.5}>
        <StatisticsCard />
      </Box>

      {props.records.length === 0 ? (
        <Center py={4}>
          <EmptyData />
        </Center>
      ) : (
        <>
          <Grid container columns={24} spacing={1}>
            {records?.map((record) => (
              <Grid item xs={24} key={record.userId}>
                <RecordCard data={record} info={eventInfo} />
              </Grid>
            ))}
          </Grid>
          <Center mt={2}>
            {hasMore ? (
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={loadMore}
                loading={loading}
                size="small"
                sx={{
                  px: 1.5,
                  py: 1,
                  fontSize: 14,
                  height: 32,
                  borderRadius: '8px',
                  fontWeight: 500,
                }}
              >
                {__('Load More')}
              </LoadingButton>
            ) : (
              <Typography>{__('Nothing more')}</Typography>
            )}
          </Center>
        </>
      )}
    </Box>
  );
};
