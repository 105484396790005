import useBoolean from '@/hooks/useBoolean';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useRef } from 'react';

interface Props {
  value: number;
  onChange: (value: number) => void;
  options: Array<{
    label: string;
    value: number;
  }>;
}

export const DaysSelect: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useBoolean();
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setOpen.off();
  };

  const handleSelect = (value: number) => () => {
    props.onChange(value);
    setOpen.off();
  };

  const getLabelByValue = (value: number) => {
    const option = props.options.find((option) => option.value === value);
    return option?.label || value;
  };

  return (
    <Box>
      <Box ref={anchorRef}>
        <Button
          variant="contained"
          sx={{
            borderRadius: '6px',
            height: 32,
            '& .MuiButton-endIcon': {
              ml: 0,
            },
            pl: 1.5,
            pr: '6px',
            py: 1,
            fontWeight: 400,
          }}
          size="small"
          endIcon={
            <ArrowDropDownOutlinedIcon sx={{ fontSize: '22px !important' }} />
          }
          onClick={setOpen.on}
        >
          {getLabelByValue(props.value)}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            bgcolor: 'card.main',
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} onClick={handleSelect(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const DaysToggler: React.FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        overflow: 'hidden',
        p: '2px',
        bgcolor: '#2E282D',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {props.options.map((option) => {
        const isActive = option.value === props.value;
        return (
          <Box
            key={option.value}
            sx={{
              px: '14px',
              py: '6px',
              borderRadius: 1,
              overflow: 'hidden',
              bgcolor: isActive ? 'primary.main' : 'transparent',
            }}
            onClick={() => {
              props.onChange(option.value);
            }}
          >
            <Typography
              variant="body2"
              lineHeight="14px"
              fontWeight={500}
              color={isActive ? 'text.main' : 'text.primary'}
            >
              {option.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
