import FIRE from '@/assets/icons/fire.png';
import useLang from '@/hooks/useLang';
import { depositTabState } from '@/stores/auth';
import { Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';

const common = {
  borderRadius: '8px',
  cursor: 'pointer',
  position: 'relative',
  pr: '44px',
};

export const DepositTabs = () => {
  const { __ } = useLang();
  const [tab, setTab] = useRecoilState(depositTabState);
  return (
    <Box
      sx={{
        height: '40px',
        my: 1.5,
        position: 'sticky',
        zIndex: 100,
        top: 50,
        left: 0,
        pl: 0,
        pr: 0,
        mx: '16px',
        display: 'flex',
        bgcolor: 'background.default',
        borderRadius: '8px',
      }}
    >
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('amount');
        }}
        sx={
          tab !== 'amount'
            ? {
                background:
                  'linear-gradient(-96deg, rgba(229, 140, 219, 0.00) 39.61%, rgba(229, 140, 219, 0.50) 104.1%)',
                // border: `1px solid transparent`,
                borderRadius: '8px',
                cursor: 'pointer',
              }
            : {
                bgcolor: 'divider',
                border: `1px solid`,
                borderColor: 'primary.main',
                borderRadius: '8px',
                cursor: 'pointer',
              }
          // bgcolor: tab === 'package' ? 'background.neutral' : '',
          // border: tab === 'package' ? `1px solid ${ColorfulBg()}` : '',
        }
        // sx={{
        //   bgcolor: tab === 'amount' ? '#3D333C' : '',
        //   border: tab === 'amount' ? `1px solid ${ColorfulBg()}` : '',
        //   borderRadius: '8px',
        //   cursor: 'pointer',
        // }}
      >
        <Typography
          variant="body1"
          fontWeight={tab === 'amount' ? 700 : 500}
          lineHeight={1}
          sx={{ textAlign: 'center' }}
          color={tab === 'amount' ? 'text.primary' : 'text.secondary'}
        >
          {__('Amount Recharge')}
        </Typography>
      </Box>
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        onClick={() => {
          setTab('package');
        }}
        sx={
          tab !== 'package'
            ? {
                background:
                  'linear-gradient(96deg, rgba(229, 140, 219, 0.00) 39.61%, rgba(229, 140, 219, 0.50) 104.1%)',
                ...common,
              }
            : {
                bgcolor: 'primary.main',
                ...common,
              }
          // bgcolor: tab === 'package' ? 'background.neutral' : '',
          // border: tab === 'package' ? `1px solid ${ColorfulBg()}` : '',
        }
      >
        <Typography
          variant="body1"
          fontWeight={tab === 'package' ? 700 : 500}
          lineHeight={1}
          color={tab === 'package' ? 'text.primary' : 'text.secondary'}
          textAlign="right"
          flex="auto"
          minWidth={0}
        >
          {__('Package Purchase')}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            width: '48px',
            height: '48px',
            bottom: 6,
          }}
        >
          <img src={FIRE} alt="" width={48} height={48} />
        </Box>
      </Box>
    </Box>
  );
};
