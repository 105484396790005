import services from '@/services';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export interface IAgentSummary {
  userId: number;
  totalCount: number; //总邀请人数（历史邀请人数）
  totalActivateCount: number; //总激活人数（历史激活人数）
  totalRechargedCount: number; //总首充人数（历史首充人数）
  registerCount: number; //该时间段内注册人数（新增人数）
  activateCount: number; //有效人数
  rechargedCount: number; //充值人数
  firstRechargeCount: number; //首充人数
  rechargeTimes: number; //充值次数
  rechargeAmt: number; //充值金额
  cashOutTimes: number; //提现次数
  cashOutAmt: number; //提现金额
  orderAmt: number; //下单金额
  orderResultAmt: number; //订单结果金额
  agentAmt: number;
  totalAgentAmt: number;
}
export interface IAgentSummary2 {
  '1LayerRechargeAmt': number; //一层用户充值金额
  totalRechargeAmt: number; //总充值金额
  '2LayerRechargeAmt': number; //二层充值金额
  '3LayerFirstRechargeTimes': number; //三层用户首充人数（次数）
  '3LayerFirstRechargeAmt': number; //三层用户首充金额
  '1LayerFirstRechargeAmt': number; //一层用户首充金额
  '2LayerFirstRechargeTimes': number; //二层用户首充人数（次数）
  '1LayerFirstRechargeTimes': number; //一层用户首充人数（次数）
  '2LayerRechargeTimes': number; //二层用户充值次数
  '2LayerFirstRechargeAmt': number; //二层用书首充金额
  totalFirstRechargeTimes: number; //总用户首充人数（次数）
  '3LayerRechargeAmt': number; //三成用户充值金额
  '1LayerRechargeTimes': number; //一层用户充值次数
  '3LayerRechargeTimes': number; //三层用户充值次数
  totalFirstRechargeAmt: number; //总首充金额
  totalRechargeTimes: number; //总充值次数
}

export interface IAgentPeople {
  id: string;
  regTime: number;
  vipState: number;
  agentState: number;
}

export const useAgentSummary = () => {
  const [data, setData] = useState<IAgentSummary | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAgentSummary = async () => {
      setIsLoading(true);

      const res = await services.config.getAgentSummary();
      if (res?.data) {
        setData(res.data);
      }
      setIsLoading(false);
    };

    fetchAgentSummary();
  }, []);

  return {
    data,
    isLoading,
  };
};

export const useAgentSummary2 = () => {
  const [start, setStart] = useState<string>(
    dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
  );
  const [end, setEnd] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [data, setData] = useState<IAgentSummary2 | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAgentSummary = async () => {
      setIsLoading(true);

      const params = {
        sSearchTime: dayjs(start).unix(),
        eSearchTime: dayjs(end).unix(),
      };

      const res = await services.config.getAgentSummary2(params);
      if (res?.data) {
        setData(res.data);
      }
      setIsLoading(false);
    };

    fetchAgentSummary();
  }, [start, end]);

  return {
    data,
    isLoading,
    setStart,
    start,
    end,
    setEnd,
  };
};
