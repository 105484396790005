import Center from '@/components/center';
import { Box, CircularProgress, Typography } from '@mui/material';

interface Props {
  tab: 3 | 2;
  title3: string;
  title2: string;
  onChange: (tab: 3 | 2) => void;
  hasTab3: boolean;
  hasTab2: boolean;
}

export const RankPageTabs = (props: Props) => {
  const handleChangeTab = (tab: 3 | 2) => () => {
    props.onChange(tab);
  };

  if (!props.hasTab2 && !props.hasTab3) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        py: 1,
      }}
    >
      {props.hasTab3 && (
        <Box mr={4} onClick={handleChangeTab(3)}>
          <TabText text={props.title3} isActive={props.tab === 3} />
          <TabBorder isActive={props.tab === 3} />
        </Box>
      )}
      {props.hasTab2 && (
        <Box onClick={handleChangeTab(2)}>
          <TabText text={props.title2} isActive={props.tab === 2} />
          <TabBorder isActive={props.tab === 2} />
        </Box>
      )}
    </Box>
  );
};

const TabBorder = (props: { isActive: boolean }) => {
  return (
    <Box px={2} mt={0.5}>
      <Box
        sx={{
          width: '100%',
          height: '4px',
          bgcolor: props.isActive ? 'primary.main' : 'transparent',
          borderRadius: '2px',
        }}
      ></Box>
    </Box>
  );
};

const TabText = (props: { text: string; isActive: boolean }) => {
  if (!props.text) {
    return (
      <Center height="20px" px={4}>
        <CircularProgress
          size={14}
          sx={{
            color: props.isActive ? 'text.primary' : 'text.secondary',
          }}
        />
      </Center>
    );
  }

  return (
    <Typography
      variant="subtitle2"
      fontWeight={props.isActive ? 700 : 500}
      fontStyle="italic"
      lineHeight={'20px'}
      color={props.isActive ? 'text.primary' : 'text.secondary'}
    >
      {props.text}
    </Typography>
  );
};
