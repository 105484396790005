import useAuth from '@/hooks/useAuth';
import services from '@/services';
import { I18nValue } from '@/types';
import { useEffect, useMemo, useState } from 'react';

export interface RankTypeDto {
  type: number;
  rankingBoardId: string;
  cycle: number;
  isTimeOut: 1 | 0;
}

export interface RankDetailDto {
  blurb: I18nValue;
  cycle: number;
  eTime: number;
  sTime: number;
  countdown: number;
  selfRanking?: number;
  selfRankingValue?: number;
  rankingBoardId: string;
  showName: I18nValue;
  round: number;
  userRankingList: UserRankDto[];
  rankingRounds: RankingRoundDto[];
  rankingRuleList: Array<{
    eRanking: number;
    prizeAmt: number;
    sRanking: number;
  }>;
  maxPrizeAmtDesc?: {
    enUS: string;
    zhCN: string;
    ptBR: string;
  };
}

export interface UserRankDto {
  ranking: number;
  rankingValue: number;
  userId: string;
  prizeAmt: number;
}

export interface RankingRoundDto {
  round: number;
  sTime: number;
  eTime: number;
}

export interface RankHistoryRuleDto {
  prizeAmt: number;
  userRankingList: UserRankDto[];
  sRanking: number;
  eRanking: number;
}

export interface RankHistoryDto {
  rankingBoardId: string;
  showName: I18nValue;
  blurb: I18nValue;
  rankingRuleList: Array<RankHistoryRuleDto>;
  round: number;
  cycle: number;
  rankingRounds: Array<RankingRoundDto>;
  sTime: number;
  eTime: number;
}

export const useRankTypes = () => {
  const [rankTypes, setRankTypes] = useState<RankTypeDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const options1 = useMemo(() => {
    const rankTypes1 = rankTypes?.filter((item) => +item.type === 3);
    return rankTypes1.map((item) => ({
      label: `${item.cycle}D`,
      value: item.cycle,
    }));
  }, [rankTypes]);

  const options2 = useMemo(() => {
    const rankTypes2 = rankTypes?.filter((item) => +item.type === 2);
    return rankTypes2.map((item) => ({
      label: `${item.cycle}D`,
      value: item.cycle,
    }));
  }, [rankTypes]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const { data } = await services.config.getRankTypes();
        if (Array.isArray(data?.data)) {
          setRankTypes(data.data);
        }
      } finally {
        setIsLoading(false);
      }
    };

    init();
  }, []);

  return {
    rankTypes,
    options1,
    options2,
    isLoading,
  };
};

export const useRank = (type: number, allRankTypes: RankTypeDto[]) => {
  const [rankType, setRankType] = useState<RankTypeDto>();
  const [rankDetail, setRankDetail] = useState<RankDetailDto>();
  const { isLogin } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const defaultRankType = useMemo(() => {
    const target = allRankTypes.find(
      (item) => item.type === type && item.isTimeOut === 0,
    );

    if (target) {
      return target;
    }

    let latestTarget: RankTypeDto | null = null;

    for (let i = 0; i < allRankTypes.length; i++) {
      const item = allRankTypes[i];
      if (item.type !== type) {
        continue;
      }

      if (!latestTarget || latestTarget.cycle < item.cycle) {
        latestTarget = item;
      }
    }

    return latestTarget;
  }, [type, allRankTypes]);

  useEffect(() => {
    if (!defaultRankType) {
      return;
    }

    if (!rankType) {
      setRankType(defaultRankType);
    }
  }, [defaultRankType, rankType]);

  const getRankList = async (rankType: RankTypeDto) => {
    setIsLoading(true);
    try {
      const { data } = await services.config.getRankList(rankType);
      data?.data &&
        setRankDetail({
          ...data.data,
          // selfRanking: 14,
        });
    } catch (error) {
      console.error(error);

      setRankDetail(undefined);
    } finally {
      setIsLoading(false);
      setIsFetched(true);
    }
  };

  useEffect(() => {
    if (!rankType) {
      return;
    }

    getRankList(rankType);
  }, [rankType, isLogin]);

  return {
    rankType,
    setRankType,
    rankDetail,
    isLoading,
    isFetched,
    refresh: async () => {
      if (rankType) {
        await getRankList(rankType);
      }
    },
  };
};

export const useRankHistory = (rankingBoardId: string, round: number) => {
  const [rankHistory, setRankHistory] = useState<RankHistoryDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isLogin } = useAuth();

  useEffect(() => {
    const getHistory = async (rankingBoardId: string, round: number) => {
      setIsLoading(true);
      try {
        const { data } = await services.config.getRankHistory({
          rankingBoardId,
          round,
        });
        data?.data && setRankHistory(data.data);
      } finally {
        setIsLoading(false);
      }
    };

    if (round && rankingBoardId) {
      getHistory(rankingBoardId, round);
    }
  }, [isLogin, rankingBoardId, round]);

  return {
    rankHistory,
    isLoading,
  };
};
