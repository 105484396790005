import useLang from '@/hooks/useLang';
import { agentSupportState } from '@/stores/config';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

export const AgentSupportList = () => {
  const { __ } = useLang();
  const angentSupportList = useRecoilValue(agentSupportState);
  return (
    <Box px={2} pb={1}>
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: 'background.neutral',
          px: 2,
          py: 1.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontStyle: 'italic',
          }}
        >
          <Box sx={{ flex: 'auto', minWidth: 0 }}>
            <Typography variant="body1" fontWeight={700} lineHeight="16px">
              {__('agentSupportTitle')}
            </Typography>
          </Box>
          <Box
            sx={{
              minWidth:
                angentSupportList.length * 32 +
                (angentSupportList.length - 1) * 8,
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {angentSupportList.map((item) => {
                return (
                  <IconButton
                    key={item.id}
                    sx={{ p: 0 }}
                    href={item.url}
                    target="_blank"
                  >
                    <img src={item.qr} alt="" width={32} height={32} />
                  </IconButton>
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
