import Center from '@/components/center';
import DatePicker from '@/components/DatePicker/DatePicker';
import EmptyData from '@/components/EmptyData';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { ColorfulBg } from '@/styles';
import { ILog } from '@/types/user';
import { hideScrollbarY } from '@/utils/cssStyles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface Props {}

export const TabLog = (props: Props) => {
  const [logs, setLogs] = useState<ILog[]>([]);
  const [loading, setLoading] = useBoolean(true);
  // const [total, setTotal] = useState<number>(0);
  const [start, setStart] = useState<string>(
    dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
  );
  const [end, setEnd] = useState<string>(
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
  );

  const { __ } = useLang();

  useEffect(() => {
    if (!start || !end) {
      return;
    }

    const startDate = dayjs(start);
    const endDate = dayjs(end);

    if (startDate.isAfter(endDate)) {
      SnackbarUtils.error(__('结束时间不能小于开始时间'));
      return;
    }

    fetchLogs({
      page: 1,
      pagesize: 200,
      start_time: `${start} 00:00:00`,
      end_time: `${end} 00:00:00`,
    });
  }, [start, end]);

  const fetchLogs = async (params: any) => {
    const res = await services.auth.getLogs(params);
    setLogs(res?.data || []);
    // setTotal(res?.total || 0);
    setLoading.off();
  };

  return (
    <Box
      height={'calc(100vh - 124px)'}
      id="invite-scroll-container"
      pb={2}
      sx={{
        ...hideScrollbarY,
      }}
    >
      <Box px={2} pt={2}>
        <Box
          sx={{
            border: `1px solid ${ColorfulBg()}`,
            borderRadius: '5px',
            py: 0,
            px: 2.5,
            mb: 2,
          }}
        >
          <Stack
            sx={{ height: '40px' }}
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Stack
              textAlign="center"
              flex="auto"
              direction="row"
              alignItems="center"
            >
              <DatePicker
                date={start}
                onChange={(v) => setStart(v)}
                maxDate={dayjs(end)}
                anchorOriginHorizontal="left"
                transformOriginHorizontal="left"
              />
              <Typography variant="body1">-</Typography>
              <DatePicker
                date={end}
                onChange={(v) => setEnd(v)}
                minDate={dayjs(start)}
                anchorOriginHorizontal="right"
                transformOriginHorizontal="right"
              />
            </Stack>
            <CalendarMonthIcon sx={{ fontSize: 16 }} />
          </Stack>
        </Box>

        <LogsList loading={loading} logs={logs} />
      </Box>
    </Box>
  );
};

const LogsList = (props: { loading: boolean; logs: ILog[] }) => {
  if (props.loading) {
    return (
      <Box>
        <Skeleton
          width="100%"
          sx={{ height: 84, mb: 1.5, bgcolor: 'background.neutral' }}
          variant="rounded"
          animation="wave"
        />
        <Skeleton
          width="100%"
          sx={{ height: 84, bgcolor: 'background.neutral' }}
          variant="rounded"
          animation="wave"
        />
      </Box>
    );
  }

  if (!props.logs.length) {
    return (
      <Center py={4}>
        <EmptyData />
      </Center>
    );
  }

  return (
    <Grid container spacing={1.5} columns={24}>
      {props.logs.map((item, idx) => {
        return (
          <Grid item xs={24} key={`${item.id}-${item.add_time}-${idx}`}>
            <Box
              sx={{
                borderRadius: 1,
                p: 2,
                bgcolor: 'background.neutral',
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                <span style={{ color: '#36A8F4', fontWeight: 500 }}>
                  R$ {(+item?.money || 0).toFixed(2)}
                </span>{' '}
                {item.infos}
              </Typography>
              <Typography mt={2} variant="body1" color="text.secondary">
                {dayjs(+(item?.add_time || 0) * 1000).format(
                  'YYYY-MM-DD HH:mm:ss',
                )}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
