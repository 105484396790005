import ICON_TOP_1 from '@/assets/icons/top_1.png';
import ICON_TOP_2 from '@/assets/icons/top_2.png';
import ICON_TOP_3 from '@/assets/icons/top_3.png';
import { Box } from '@mui/material';

export const RankListIndex = ({ rank }: { rank: number }) => {
  if (rank > 3) {
    return (
      <Box
        sx={{
          fontSize: 14,
          fontWeight: 700,
          width: '40px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
      >
        {rank}°
      </Box>
    );
  }

  if (rank > 0) {
    return (
      <Box
        sx={{
          width: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={rank === 1 ? ICON_TOP_1 : rank === 2 ? ICON_TOP_2 : ICON_TOP_3}
          alt=""
          width={16}
          height={16}
        />
      </Box>
    );
  }

  return null;
};
